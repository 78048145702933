import * as React from 'react';
import { cn } from '@/utils/utils';
import Logo from '@/public/assets/logo.svg';
import { StepperMobile } from '@/components/stepper/mobile/StepperMobile';
import { MobileHeaderMenu } from '@/components/header/mobileHeaderMenu/MobileHeaderMenu';
import { useTranslation } from 'next-i18next';

enum MobileHeaderVariant {
  Transparent = 'Transparent',
  White = 'White',
  Yellow = 'Yellow',
  Black = 'Black'
}

interface Props {
  variant?: MobileHeaderVariant;
  guest?: boolean;
  orderStep?: number;
  className?: string;
}

const MobileHeader: React.FC<Props> = ({ variant = MobileHeaderVariant.Black, guest = true, orderStep, className }) => {
  const { t } = useTranslation();

  return (
    <header
      className={cn(
        'h-[4.5rem]',
        'w-full',
        'px-6',
        'flex',
        'justify-between',
        'items-center',
        {
          'bg-transparent': variant == MobileHeaderVariant.Transparent,
          'bg-common-white': variant == MobileHeaderVariant.White,
          'bg-common-black': variant == MobileHeaderVariant.Black,
          'bg-yellow-100': variant == MobileHeaderVariant.Yellow
        },
        className
      )}
    >
      <a href={t('global:aneoMobilityUrl')} aria-label={t('global:aneoMobilityLink')}>
        <Logo
          className={cn({
            'fill-common-black':
              variant == MobileHeaderVariant.Transparent ||
              variant == MobileHeaderVariant.White ||
              variant == MobileHeaderVariant.Yellow,
            'fill-yellow-100': variant == MobileHeaderVariant.Black
          })}
        />
      </a>
      {!guest && <MobileHeaderMenu />}
      {orderStep != undefined && <StepperMobile hideTitle={!guest} step={orderStep} />}
    </header>
  );
};
export { MobileHeader, MobileHeaderVariant };
