import React, { useState } from 'react';
import * as Portal from '@radix-ui/react-portal';
import { LegacyButton, LegacyButtonSize, LegacyButtonVariant } from '@/components/LegacyButton';
import { cn } from '@/utils/utils';
import ChargingCable from '@/public/assets/icons/chargingCable.svg';
import Consumption from '@/public/assets/icons/consumption.svg';
import Receipt from '@/public/assets/icons/receipt.svg';
import User from '@/public/assets/icons/user.svg';
import Support from '@/public/assets/icons/support.svg';
import Close from '@/public/assets/icons/close.svg';
import Logout from '@/public/assets/icons/logout.svg';
import { useTranslation } from 'next-i18next';
import { Typography } from '@/components/Typography';
import { RemoveScroll } from 'react-remove-scroll';
import Link from 'next/link';
import { NavigationEntries } from '@/components/header/DesktopHeaderMenu';
import { Button } from '@aneo-org/design-react/clientComponents';
import Menu from '@/public/assets/icons/menu.svg';

export const MobileHeaderMenu: React.FC = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [swingIn, setSwingIn] = useState(true);

  const openMenu = () => {
    setOpen(true);
  };

  const closeMenu = () => {
    setSwingIn(false);
    setTimeout(() => {
      setOpen(false);
      setSwingIn(true);
    }, 300);
  };

  const navigationEntries: NavigationEntries = [
    {
      text: t('global:navigation.charger'),
      path: '/my-page/charger',
      icon: (
        <ChargingCable data-cy="chargerLinkIcon" className={cn('w-[32px]', 'shrink-0', '[&>path]:fill-common-white')} />
      )
    },
    {
      text: t('global:navigation.consumption'),
      path: '/my-page/consumption',
      icon: (
        <Consumption
          data-cy="consumptionLinkIcon"
          className={cn('w-[32px]', 'shrink-0', '[&>path]:fill-common-white')}
        />
      )
    },
    {
      text: t('global:navigation.profile'),
      path: '/my-page/profile',
      icon: <User data-cy="profileLinkIcon" className={cn('w-[32px]', 'shrink-0', '[&>path]:fill-common-white')} />
    },
    {
      text: t('global:navigation.receipt'),
      path: '/my-page/receipts',
      icon: <Receipt data-cy="receiptLinkIcon" className={cn('w-[32px]', 'shrink-0', '[&>path]:fill-common-white')} />
    },
    {
      text: t('global:navigation.contact'),
      href: t('global:links.support'),
      icon: <Support data-cy="contactLinkIcon" className={cn('w-[32px]', 'shrink-0', '[&>path]:fill-common-white')} />
    },
    {
      text: t('global:navigation.logout'),
      path: '/auth/logout',
      icon: <Logout data-cy="logoutLinkIcon" className={cn('w-[32px]', 'shrink-0', '[&>path]:fill-common-white')} />
    }
  ];

  return (
    <>
      {open && (
        <Portal.Root
          className={cn(
            'fixed',
            'inset-0',
            'z-50',
            'pointer-events-auto',
            // custom animation class
            { 'swing-in': swingIn },
            { 'swing-out': !swingIn }
          )}
        >
          <RemoveScroll>
            <div
              role="navigation"
              id="mobile-navigation-menu"
              aria-describedby="mobile-navigation-menu"
              aria-labelledby="mobile-navigation-menu"
              data-state={open}
              className={cn(
                'fixed',
                'top-2/4',
                'left-2/4',
                'h-[580px]',
                'max-h-[90vh]',
                'w-md',
                'translate-x-[-50%]',
                'translate-y-[-50%]',
                'overflow-y-auto',
                'bg-common-black',
                'text-common-white',
                'md:top-0',
                'md:left-0',
                'md:h-full',
                'md:max-h-none',
                'md:w-full',
                'md:max-w-none',
                'md:transform-none',
                'md:pb-16',
                'md:px-6',
                'pointer-events-auto'
              )}
              tabIndex={-1}
            >
              <div className={cn('flex', 'flex-col', 'gap-12')}>
                <div
                  className={cn(
                    // same as mobile header height
                    'h-[4.5rem]',
                    'flex',
                    'justify-end',
                    'items-center'
                  )}
                >
                  <Button variant="primary" size="small" onClick={closeMenu}>
                    {t('global:navigation.closeMenu')}
                  </Button>
                </div>
                <div>
                  <ul className={cn('flex', 'flex-col', 'gap-8')}>
                    {navigationEntries.map(entry => {
                      return (
                        <li key={entry.path ?? entry.href}>
                          <Typography
                            type="S-H"
                            component={entry.path ? Link : 'a'}
                            href={entry.path ?? entry.href}
                            onClick={closeMenu}
                            className={cn(
                              'flex',
                              'items-center',
                              'px-3',
                              'py-1',
                              'rounded-lg',
                              'active:hover:bg-gray-100'
                            )}
                          >
                            <span className={cn('flex', 'gap-3', 'items-center')}>
                              {entry.icon ? entry.icon : <div className={cn('w-[32px]', 'h-[32px]')}></div>}
                              {entry.text}
                            </span>
                          </Typography>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </RemoveScroll>
        </Portal.Root>
      )}
      <Button variant="ghost" size="small" onClick={openMenu}>
        <Menu className={cn('w-[24px]')} />
      </Button>
    </>
  );
};
