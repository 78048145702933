import * as React from 'react';
import { cn } from '@/utils/utils';
import { LegacyButton, LegacyButtonSize, LegacyButtonVariant } from '@/components/LegacyButton';
import { Typography } from '@/components/Typography';
import Chevron from '@/public/assets/icons/chevron.svg';
import AnimateHeight from 'react-animate-height';
import { useTranslation } from 'next-i18next';

enum AlertType {
  Alert = 'Alert',
  Warning = 'Warning',
  Info = 'Info',
  Success = 'Success'
}

interface Props {
  type?: AlertType;
  header?: JSX.Element | string;
  body?: JSX.Element | string;
  buttonText?: string;
  buttonSize?: LegacyButtonSize;
  buttonSizeMobile?: LegacyButtonSize;
  buttonOnClick?: (e?: React.SyntheticEvent) => void;
  buttonDisabled?: boolean;
  buttonIsLoading?: boolean;
  className?: string;
  collapsable?: boolean;
  initiallyCollapsed?: boolean;
}

const Alert: React.FC<Props> = ({
  type = AlertType.Info,
  header,
  body,
  buttonText,
  buttonSize = LegacyButtonSize.Small,
  buttonSizeMobile = LegacyButtonSize.Medium,
  buttonOnClick,
  buttonIsLoading,
  buttonDisabled,
  className,
  collapsable = false,
  initiallyCollapsed = true
}) => {
  const { t } = useTranslation();

  const [isCollapsed, setIsCollapsed] = React.useState<boolean>(initiallyCollapsed);
  const toggleIsCollapsed = () => setIsCollapsed(value => !value);

  return (
    <div
      className={cn(className, 'flex', 'flex-col', 'p-5', 'w-full', 'md:p-4', 'border-[3px]', {
        'bg-system-red-20': type == AlertType.Alert,
        'border-system-red-100': type == AlertType.Alert,
        'bg-yellow-50': type == AlertType.Warning,
        'border-yellow-100': type == AlertType.Warning,
        'bg-blue-25': type == AlertType.Info,
        'border-blue-100': type == AlertType.Info,
        'bg-green-25': type == AlertType.Success,
        'border-green-100': type == AlertType.Success
      })}
    >
      {collapsable && (
        <button
          onClick={toggleIsCollapsed}
          className={cn('flex', 'justify-between', 'gap-8', 'text-start', 'p-5', '-m-5', 'md:p-4', 'md:-m-4')}
        >
          {header && (
            <Typography type="S-H" weight="medium" component="h3" className={cn('max-w-[530px]')}>
              {header}
            </Typography>
          )}
          <div className={cn('ml-auto')}>
            <Chevron
              width="1.5rem"
              height="1.5rem"
              className={cn('fill-common-black', 'transition-transform', {
                'rotate-90': isCollapsed,
                '-rotate-90': !isCollapsed
              })}
            />
          </div>
        </button>
      )}
      {!collapsable && header && (
        <div className={cn('flex')}>
          <Typography type="S-H" component="h3" weight="medium" className={cn('max-w-[530px]')}>
            {header}
          </Typography>
        </div>
      )}
      <AnimateHeight duration={500} animateOpacity={true} height={!isCollapsed || !collapsable ? 'auto' : 0}>
        {(body || buttonText) && (
          <div className={cn({ 'mt-2': header })}>
            {body && (
              <Typography
                type="S-B"
                component={typeof body == 'string' ? 'p' : 'div'}
                className={cn('max-w-[530px]', {
                  'mb-6': collapsable || buttonText != undefined,
                  'md:mb-8': collapsable || buttonText != undefined
                })}
              >
                {body}
              </Typography>
            )}
            <div className={cn('flex', 'gap-4')}>
              {buttonText && (
                <LegacyButton
                  buttonVariant={LegacyButtonVariant.Outline}
                  buttonSize={buttonSize}
                  buttonSizeMobile={buttonSizeMobile}
                  onClick={buttonOnClick}
                  disabled={buttonDisabled}
                  loading={buttonIsLoading}
                >
                  {buttonText}
                </LegacyButton>
              )}
              {collapsable && (
                <LegacyButton
                  buttonVariant={LegacyButtonVariant.Outline}
                  buttonSize={buttonSize}
                  buttonSizeMobile={buttonSizeMobile}
                  onClick={toggleIsCollapsed}
                >
                  {t('global:close')}
                </LegacyButton>
              )}
            </div>
          </div>
        )}
      </AnimateHeight>
    </div>
  );
};

export { Alert, AlertType };
